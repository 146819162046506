export default {
  v: "5.9.0",
  fr: 60,
  ip: 0,
  op: 80,
  w: 950,
  h: 1080,
  nm: "sureti Logo",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [868.619, 207.338, 0], ix: 2, l: 2 },
        a: { a: 0, k: [39.215, 73.87, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.2, -0.16],
                    [0, 0],
                    [0.18, 0.12],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.18, -0.12],
                    [0, 0],
                    [0.2, 0.15],
                  ],
                  v: [
                    [38.965, -73.15],
                    [-38.425, 73.62],
                    [-38.965, 73.27],
                    [38.365, -73.62],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [39.215, 73.87], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 80,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [852.779, 196.638, 0], ix: 2, l: 2 },
        a: { a: 0, k: [55.055, 84.57, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-9.41, -7.5],
                    [0, 0],
                    [10.91, 6.22],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-10.57, -6.87],
                    [0, 0],
                    [11.33, 7.06],
                  ],
                  v: [
                    [54.805, -62.45],
                    [-22.585, 84.32],
                    [-54.805, 64.68],
                    [23.635, -84.32],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [55.055, 84.57], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 1.002002002002,
      op: 80,
      st: 1.002002002002,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [834.964, 186.023, 0], ix: 2, l: 2 },
        a: { a: 0, k: [72.87, 95.185, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-19.21, -15.31],
                    [0, 0],
                    [23.28, 11.46],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-21.91, -14.23],
                    [0, 0],
                    [26.76, 13.29],
                  ],
                  v: [
                    [72.62, -51.835],
                    [-4.77, 94.935],
                    [-72.62, 56.375],
                    [3.2, -94.935],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [72.87, 95.185], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 2.004004004004,
      op: 80,
      st: 2.004004004004,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Layer 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [816.284, 176.913, 0], ix: 2, l: 2 },
        a: { a: 0, k: [91.55, 104.295, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-28.42, -22.65],
                    [0, 0],
                    [36.51, 15.23],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-33.47, -21.75],
                    [0, 0],
                    [43.98, 17.77],
                  ],
                  v: [
                    [91.3, -42.725],
                    [13.91, 104.045],
                    [-91.3, 48.515],
                    [-18.82, -104.045],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [91.55, 104.295], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 3.00600600600601,
      op: 80,
      st: 3.00600600600601,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Layer 5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [795.179, 168.658, 0], ix: 2, l: 2 },
        a: { a: 0, k: [112.655, 112.55, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-37.78, -30.11],
                    [0, 0],
                    [51.66, 17.49],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-46.21, -30.02],
                    [0, 0],
                    [63.49, 20.71],
                  ],
                  v: [
                    [112.405, -34.47],
                    [35.015, 112.3],
                    [-112.405, 40.85],
                    [-43.085, -112.3],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [112.655, 112.55], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 4.00800800800801,
      op: 80,
      st: 4.00800800800801,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Layer 6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [772.984, 161.453, 0], ix: 2, l: 2 },
        a: { a: 0, k: [134.85, 119.755, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-47.32, -37.71],
                    [0, 0],
                    [67.71, 17.72],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-59.3, -38.53],
                    [0, 0],
                    [84.81, 21.69],
                  ],
                  v: [
                    [134.6, -27.265],
                    [57.21, 119.505],
                    [-134.6, 34.745],
                    [-70.6, -119.505],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [134.85, 119.755], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 5.01001001001001,
      op: 80,
      st: 5.01001001001001,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Layer 7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [752.589, 156.988, 0], ix: 2, l: 2 },
        a: { a: 0, k: [155.245, 124.22, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-54.36, -43.32],
                    [0, 0],
                    [82.47, 16.06],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-71.11, -46.2],
                    [0, 0],
                    [101.1, 20.95],
                  ],
                  v: [
                    [154.995, -22.8],
                    [77.605, 123.97],
                    [-154.995, 29.91],
                    [-88.815, -123.97],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [155.245, 124.22], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 6.01201201201201,
      op: 80,
      st: 6.01201201201201,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Layer 8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [736.639, 152.393, 0], ix: 2, l: 2 },
        a: { a: 0, k: [171.195, 128.815, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-63.51, -50.62],
                    [0, 0],
                    [93.96, 13.55],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-80.23, -52.13],
                    [0, 0],
                    [122.43, 17.9],
                  ],
                  v: [
                    [170.945, -18.205],
                    [93.555, 128.565],
                    [-170.945, 29.095],
                    [-124.915, -128.565],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [171.195, 128.815], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 7.01401401401401,
      op: 80,
      st: 7.01401401401401,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Layer 9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [712.914, 148.818, 0], ix: 2, l: 2 },
        a: { a: 0, k: [194.92, 132.39, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-74.7, -59.54],
                    [0, 0],
                    [110.9, 7.85],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-93.66, -60.85],
                    [0, 0],
                    [147.95, 10.58],
                  ],
                  v: [
                    [194.67, -14.63],
                    [117.28, 132.14],
                    [-194.67, 27.57],
                    [-166.89, -132.14],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [194.92, 132.39], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 8.01601601601602,
      op: 80,
      st: 8.01601601601602,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Layer 10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [691.129, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [216.705, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [126.22, 0.55],
                    [0, 0],
                    [-1.62, 0],
                  ],
                  o: [
                    [0, 0],
                    [-105.88, -68.79],
                    [0, 0],
                    [1.61, -0.02],
                    [169.93, 0],
                  ],
                  v: [
                    [216.455, -13.52],
                    [139.065, 133.25],
                    [-216.455, 27.05],
                    [-211.325, -133.23],
                    [-206.475, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [216.705, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 9.01801801801802,
      op: 80,
      st: 9.01801801801802,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "Layer 11",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [662.559, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [245.275, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [13.96, -0.84],
                    [0, 0],
                    [-23.13, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-15.59, 0],
                    [0, 0],
                    [21.6, -1.67],
                    [169.93, 0],
                  ],
                  v: [
                    [245.025, -13.52],
                    [167.635, 133.25],
                    [-191.575, 27.04],
                    [-235.885, 28.31],
                    [-245.025, -130.73],
                    [-177.905, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [245.275, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10.02002002002,
      op: 80,
      st: 10.02002002002,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "Layer 12",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [633.179, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [274.655, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [26.03, -3.8],
                    [0, 0],
                    [-44.84, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-33.4, 0],
                    [0, 0],
                    [38.98, -6.17],
                    [169.93, 0],
                  ],
                  v: [
                    [274.405, -13.52],
                    [197.015, 133.25],
                    [-162.195, 27.04],
                    [-251.225, 32.8],
                    [-274.405, -123.89],
                    [-148.525, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [274.655, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 11.022022022022,
      op: 80,
      st: 11.022022022022,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: "Layer 13",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [608.999, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [298.835, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [33.01, -7.78],
                    [0, 0],
                    [-64.04, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-48.11, 0],
                    [0, 0],
                    [51.83, -12.38],
                    [169.93, 0],
                  ],
                  v: [
                    [298.585, -13.52],
                    [221.195, 133.25],
                    [-138.015, 27.04],
                    [-259.385, 38.89],
                    [-298.585, -114.36],
                    [-124.345, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [298.835, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 12.024024024024,
      op: 80,
      st: 12.024024024024,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: "Layer 14",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [581.214, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [326.62, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [38.44, -15.47],
                    [0, 0],
                    [-88.07, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-68.57, 0],
                    [0, 0],
                    [64.37, -22.96],
                    [169.93, 0],
                  ],
                  v: [
                    [326.37, -13.52],
                    [248.98, 133.25],
                    [-110.23, 27.04],
                    [-269.84, 50.78],
                    [-326.37, -97.97],
                    [-96.56, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [326.62, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 13.026026026026,
      op: 80,
      st: 13.026026026026,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: "Layer 15",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [546.384, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [361.45, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [38.8, -27.7],
                    [0, 0],
                    [-122.65, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-92.91, 0],
                    [0, 0],
                    [74.93, -43.21],
                    [169.93, 0],
                  ],
                  v: [
                    [361.2, -13.52],
                    [283.81, 133.25],
                    [-75.4, 27.04],
                    [-270.68, 69.93],
                    [-361.2, -66.14],
                    [-61.73, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [361.45, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 14.028028028028,
      op: 80,
      st: 14.028028028028,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: "Layer 16",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [506.649, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [401.185, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [32.23, -44.41],
                    [0, 0],
                    [-173.98, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-119.27, 0],
                    [0, 0],
                    [72.77, -83.06],
                    [169.93, 0],
                  ],
                  v: [
                    [400.935, -13.52],
                    [323.545, 133.25],
                    [-35.665, 27.04],
                    [-258.085, 96.47],
                    [-400.935, -2.11],
                    [-21.995, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [401.185, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 15.03003003003,
      op: 80,
      st: 15.03003003003,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: "Layer 17",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [483.984, 147.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [423.85, 133.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [19.49, -63.79],
                    [0, 0],
                    [-220.55, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-144.96, 0],
                    [0, 0],
                    [50.34, -127.82],
                    [169.93, 0],
                  ],
                  v: [
                    [423.6, -13.52],
                    [346.21, 133.25],
                    [-13, 27.04],
                    [-250.98, 127.8],
                    [-423.6, 71.84],
                    [0.67, -133.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [423.85, 133.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 16.032032032032,
      op: 80,
      st: 16.032032032032,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: "Layer 18",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [476.069, 160.038, 0], ix: 2, l: 2 },
        a: { a: 0, k: [431.765, 145.83, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [4.41, -82.13],
                    [0, 0],
                    [-254.67, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-166.45, 0],
                    [0, 0],
                    [20.32, -164.92],
                    [169.93, 0],
                  ],
                  v: [
                    [431.515, -25.85],
                    [354.125, 120.92],
                    [-5.085, 14.71],
                    [-248.215, 145.58],
                    [-431.515, 122.36],
                    [8.585, -145.58],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [431.765, 145.83], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 17.034034034034,
      op: 80,
      st: 17.034034034034,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: "Layer 19",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 177.633, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.835, 163.425, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-2.24, -8.28],
                    [0, 0],
                    [-0.01, 7.99],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 9.76],
                    [0, 0],
                    [-0.52, -7.52],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.585, -43.445],
                    [355.195, 103.325],
                    [-4.015, -2.885],
                    [-247.365, 136.155],
                    [-243.955, 163.175],
                    [-431.815, 163.175],
                    [-432.575, 139.925],
                    [9.655, -163.175],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.835, 163.425], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 18.036036036036,
      op: 80,
      st: 18.036036036036,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: "Layer 20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.984, 210.298, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.85, 196.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-6.66, -12.78],
                    [0, 0],
                    [-0.04, 33.55],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 17.13],
                    [0, 0],
                    [-8.42, -25.71],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.6, -76.11],
                    [355.21, 70.66],
                    [-4, -35.55],
                    [-247.35, 103.49],
                    [-237.09, 148.14],
                    [-419.6, 195.84],
                    [-432.56, 107.26],
                    [9.67, -195.84],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.85, 196.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 19.038038038038,
      op: 80,
      st: 19.038038038038,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: "Layer 21",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.959, 252.433, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.875, 238.225, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-15.85, -16.85],
                    [0, 0],
                    [-0.09, 77.76],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 27.08],
                    [0, 0],
                    [-39.8, -40.04],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.625, -118.245],
                    [355.235, 28.525],
                    [-3.975, -77.685],
                    [-247.325, 61.355],
                    [-222.475, 126.395],
                    [-368.125, 237.975],
                    [-432.535, 65.125],
                    [9.695, -237.975],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.875, 238.225], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 20.04004004004,
      op: 80,
      st: 20.04004004004,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: "Layer 22",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.944, 275.553, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.89, 261.345, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-29.52, -19.21],
                    [0, 0],
                    [-0.12, 113.31],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 37.99],
                    [0, 0],
                    [-75.16, -40.7],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.64, -141.365],
                    [355.25, 5.405],
                    [-3.96, -100.805],
                    [-247.31, 38.235],
                    [-200.08, 121.675],
                    [-304.99, 261.095],
                    [-432.52, 42.005],
                    [9.71, -261.095],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.89, 261.345], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 21.042042042042,
      op: 80,
      st: 21.042042042042,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: "Layer 23",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.919, 292.983, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.915, 278.775, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-51.86, -20.09],
                    [-1.86, -0.65],
                    [0, 0],
                    [-0.17, 153.05],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 52.36],
                    [0.6, 0.22],
                    [0, 0],
                    [-118.02, -35.84],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.665, -158.795],
                    [355.275, -12.025],
                    [-3.935, -118.235],
                    [-247.285, 20.805],
                    [-161.785, 123.305],
                    [-158.035, 124.625],
                    [-218.815, 278.525],
                    [-432.495, 24.575],
                    [9.735, -278.525],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.915, 278.775], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 22.044044044044,
      op: 80,
      st: 22.044044044044,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: "Layer 24",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.904, 305.193, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.93, 290.985, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-84.97, -67.72],
                    [0, 0],
                    [127.5, -0.16],
                    [0, -87.06],
                    [-51.86, -20.09],
                    [-23.75, -6.29],
                    [0, 0],
                    [8.08, 1.83],
                    [0, 0],
                    [-0.2, 182.67],
                    [-272.31, 0],
                  ],
                  o: [
                    [0, 0],
                    [-106.91, -69.46],
                    [-171.9, 0],
                    [0, 52.36],
                    [3.02, 1.08],
                    [0, 0],
                    [-12.61, -2.25],
                    [0, 0],
                    [-147.81, -31.89],
                    [0, -185.31],
                    [169.93, 0],
                  ],
                  v: [
                    [432.68, -171.005],
                    [355.29, -24.235],
                    [-3.92, -130.445],
                    [-247.27, 8.595],
                    [-161.77, 111.095],
                    [-114.73, 125.625],
                    [-114.73, 290.735],
                    [-148.31, 284.255],
                    [-148.32, 284.255],
                    [-432.48, 12.365],
                    [9.75, -290.735],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.93, 290.985], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 23.046046046046,
      op: 80,
      st: 23.046046046046,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: "Layer 25",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.903, 305.297, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.928, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [169.928, 0],
                    [0, -185.311],
                    [-147.808, -31.891],
                    [-0.003, 0],
                    [-12.976, -2.309],
                    [0, 0],
                    [3.069, 1.097],
                    [0, 52.365],
                    [-171.897, 0],
                    [-106.912, -69.468],
                    [0, 0],
                  ],
                  o: [
                    [-272.309, 0],
                    [-0.194, 182.666],
                    [0.004, 0],
                    [8.325, 1.875],
                    [0, 0],
                    [-24.245, -6.356],
                    [-51.863, -20.093],
                    [0, -87.062],
                    [127.498, -0.156],
                    [0, 0],
                    [-84.967, -67.714],
                  ],
                  v: [
                    [9.754, -290.84],
                    [-432.484, 12.262],
                    [-148.318, 284.149],
                    [-148.311, 284.152],
                    [-113.584, 290.84],
                    [-113.584, 125.821],
                    [-161.768, 110.992],
                    [-247.27, 8.49],
                    [-3.919, -130.55],
                    [355.289, -24.336],
                    [432.678, -171.114],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.928, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 24.048048048048,
      op: 80,
      st: 24.048048048048,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: "Layer 26",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [589.359, 569.338, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.825, 82.91, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.38, -0.07],
                    [0, 0],
                    [0.39, 0.1],
                  ],
                  o: [
                    [0.38, 0.07],
                    [0, 0],
                    [-0.38, -0.1],
                    [0, 0],
                  ],
                  v: [
                    [-0.575, -82.66],
                    [0.575, -82.45],
                    [0.575, 82.66],
                    [-0.575, 82.36],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.825, 82.91], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 26.0500500500501,
      op: 80,
      st: 26.0500500500501,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: "Layer 27",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [641.394, 575.943, 0], ix: 2, l: 2 },
        a: { a: 0, k: [52.86, 89.515, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-22.84, -6.93],
                    [0, 0],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                  ],
                  v: [
                    [52.61, -64.635],
                    [-8.17, 89.265],
                    [-51.46, 76.055],
                    [-52.61, 75.755],
                    [-52.61, -89.265],
                    [-51.46, -89.055],
                    [-17.88, -82.575],
                    [-17.87, -82.575],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [52.86, 89.515], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 27.0520520520521,
      op: 80,
      st: 27.0520520520521,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: "Layer 28",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [684.469, 586.133, 0], ix: 2, l: 2 },
        a: { a: 0, k: [95.935, 99.705, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-26.36, -14.28],
                    [0, 0],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                  ],
                  o: [
                    [0, 0],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                  ],
                  v: [
                    [95.685, -39.965],
                    [-9.225, 99.455],
                    [-47.495, 80.395],
                    [-51.245, 79.075],
                    [-94.535, 65.865],
                    [-95.685, 65.565],
                    [-95.685, -99.455],
                    [-94.535, -99.245],
                    [-60.955, -92.765],
                    [-60.945, -92.765],
                    [9.535, -74.825],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [95.935, 99.705], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 28.0540540540541,
      op: 80,
      st: 28.0540540540541,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: "Layer 29",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [716.029, 595.508, 0], ix: 2, l: 2 },
        a: { a: 0, k: [127.495, 109.08, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.11, -0.12],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                  ],
                  o: [
                    [0.1, 0.11],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [0, 0],
                  ],
                  v: [
                    [-18.405, 108.48],
                    [-18.085, 108.83],
                    [-40.785, 90.08],
                    [-79.055, 71.02],
                    [-82.805, 69.7],
                    [-126.095, 56.49],
                    [-127.245, 56.19],
                    [-127.245, -108.83],
                    [-126.095, -108.62],
                    [-92.515, -102.14],
                    [-92.505, -102.14],
                    [-22.025, -84.2],
                    [64.125, -49.34],
                    [127.245, -3.1],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [127.495, 109.08], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 29.0560560560561,
      op: 80,
      st: 29.0560560560561,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: "Layer 30",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [741.754, 605.528, 0], ix: 2, l: 2 },
        a: { a: 0, k: [153.22, 119.1, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-11.07, -33.89],
                    [0, 0],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                  ],
                  o: [
                    [0, 0],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                  ],
                  v: [
                    [152.97, 71.15],
                    [-29.54, 118.85],
                    [-43.81, 98.81],
                    [-66.51, 80.06],
                    [-104.78, 61],
                    [-108.53, 59.68],
                    [-151.82, 46.47],
                    [-152.97, 46.17],
                    [-152.97, -118.85],
                    [-151.82, -118.64],
                    [-118.24, -112.16],
                    [-118.23, -112.16],
                    [-47.75, -94.22],
                    [38.4, -59.36],
                    [101.52, -13.12],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [153.22, 119.1], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 30.0580580580581,
      op: 80,
      st: 30.0580580580581,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 4,
      nm: "Layer 31",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [747.854, 614.343, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.32, 127.915, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.61, -24.05],
                    [0, 0],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                  ],
                  o: [
                    [0, 0],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                  ],
                  v: [
                    [159.07, 127.665],
                    [-28.79, 127.665],
                    [-35.64, 110.035],
                    [-49.91, 89.995],
                    [-72.61, 71.245],
                    [-110.88, 52.185],
                    [-114.63, 50.865],
                    [-157.92, 37.655],
                    [-159.07, 37.355],
                    [-159.07, -127.665],
                    [-157.92, -127.455],
                    [-124.34, -120.975],
                    [-124.33, -120.975],
                    [-53.85, -103.035],
                    [32.3, -68.175],
                    [95.42, -21.935],
                    [146.87, 62.335],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.32, 127.915], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 31.0600600600601,
      op: 80,
      st: 31.0600600600601,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 4,
      nm: "Layer 32",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 643.548, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 157.12, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [0, 0],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [0, 0],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, 121.71],
                    [157.315, 156.87],
                    [-25.985, 133.65],
                    [-25.765, 125.48],
                    [-29.175, 98.46],
                    [-36.025, 80.83],
                    [-50.295, 60.79],
                    [-72.995, 42.04],
                    [-111.265, 22.98],
                    [-115.015, 21.66],
                    [-158.305, 8.45],
                    [-159.455, 8.15],
                    [-159.455, -156.87],
                    [-158.305, -156.66],
                    [-124.725, -150.18],
                    [-124.715, -150.18],
                    [-54.235, -132.24],
                    [31.915, -97.38],
                    [95.035, -51.14],
                    [146.485, 33.13],
                    [158.685, 98.46],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 157.12], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 32.0620620620621,
      op: 80,
      st: 32.0620620620621,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 4,
      nm: "Layer 33",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 674.973, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 188.545, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [0, 0],
                    [0.09, -0.27],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [0, 0],
                    [-0.08, 0.27],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, 90.285],
                    [157.315, 125.445],
                    [141.485, 188.295],
                    [-31.135, 132.335],
                    [-31.395, 133.145],
                    [-25.985, 102.225],
                    [-25.765, 94.055],
                    [-29.175, 67.035],
                    [-36.025, 49.405],
                    [-50.295, 29.365],
                    [-72.995, 10.615],
                    [-111.265, -8.445],
                    [-115.015, -9.765],
                    [-158.305, -22.975],
                    [-159.455, -23.275],
                    [-159.455, -188.295],
                    [-158.305, -188.085],
                    [-124.725, -181.605],
                    [-124.715, -181.605],
                    [-54.235, -163.665],
                    [31.915, -128.805],
                    [95.035, -82.565],
                    [146.485, 1.705],
                    [158.685, 67.035],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 188.545], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 33.0640640640641,
      op: 80,
      st: 33.0640640640641,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 4,
      nm: "Layer 34",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 711.948, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 225.52, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, 53.31],
                    [157.315, 88.47],
                    [141.485, 151.32],
                    [96.155, 225.27],
                    [-46.685, 126.69],
                    [-31.395, 96.17],
                    [-25.985, 65.25],
                    [-25.765, 57.08],
                    [-29.175, 30.06],
                    [-36.025, 12.43],
                    [-50.295, -7.61],
                    [-72.995, -26.36],
                    [-111.265, -45.42],
                    [-115.015, -46.74],
                    [-158.305, -59.95],
                    [-159.455, -60.25],
                    [-159.455, -225.27],
                    [-158.305, -225.06],
                    [-124.725, -218.58],
                    [-124.715, -218.58],
                    [-54.235, -200.64],
                    [31.915, -165.78],
                    [95.035, -119.54],
                    [146.485, -35.27],
                    [158.685, 30.06],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 225.52], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 34.0660660660661,
      op: 80,
      st: 34.0660660660661,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 35,
      ty: 4,
      nm: "Layer 35",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 743.963, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 257.535, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [0, 0],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [0, 0],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, 21.295],
                    [157.315, 56.455],
                    [141.485, 119.305],
                    [96.155, 193.255],
                    [17.365, 256.915],
                    [17.345, 256.925],
                    [17.215, 256.995],
                    [17.125, 257.045],
                    [17.045, 257.085],
                    [16.685, 257.285],
                    [-73.835, 121.215],
                    [-46.695, 94.675],
                    [-46.685, 94.675],
                    [-31.395, 64.155],
                    [-25.985, 33.235],
                    [-25.765, 25.065],
                    [-29.175, -1.955],
                    [-36.025, -19.585],
                    [-50.295, -39.625],
                    [-72.995, -58.375],
                    [-111.265, -77.435],
                    [-115.015, -78.755],
                    [-158.305, -91.965],
                    [-159.455, -92.265],
                    [-159.455, -257.285],
                    [-158.305, -257.075],
                    [-124.725, -250.595],
                    [-124.715, -250.595],
                    [-54.235, -232.655],
                    [31.915, -197.795],
                    [95.035, -151.555],
                    [146.485, -67.285],
                    [158.685, -1.955],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 257.535], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 35.0680680680681,
      op: 80,
      st: 35.0680680680681,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 36,
      ty: 4,
      nm: "Layer 36",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 759.878, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 273.45, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [0, 0],
                    [0.28, -0.11],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [0, 0],
                    [-0.28, 0.11],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, 5.38],
                    [157.315, 40.54],
                    [141.485, 103.39],
                    [96.155, 177.34],
                    [17.365, 241],
                    [17.345, 241.01],
                    [17.215, 241.08],
                    [17.125, 241.13],
                    [17.045, 241.17],
                    [16.685, 241.37],
                    [-52.975, 273.2],
                    [-109.505, 124.45],
                    [-110.345, 124.78],
                    [-73.835, 105.3],
                    [-46.695, 78.76],
                    [-46.685, 78.76],
                    [-31.395, 48.24],
                    [-25.985, 17.32],
                    [-25.765, 9.15],
                    [-29.175, -17.87],
                    [-36.025, -35.5],
                    [-50.295, -55.54],
                    [-72.995, -74.29],
                    [-111.265, -93.35],
                    [-115.015, -94.67],
                    [-158.305, -107.88],
                    [-159.455, -108.18],
                    [-159.455, -273.2],
                    [-158.305, -272.99],
                    [-124.725, -266.51],
                    [-124.715, -266.51],
                    [-54.235, -248.57],
                    [31.915, -213.71],
                    [95.035, -167.47],
                    [146.485, -83.2],
                    [158.685, -17.87],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 273.45], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 36.0700700700701,
      op: 80,
      st: 36.0700700700701,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 37,
      ty: 4,
      nm: "Layer 37",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.239, 768.068, 0], ix: 2, l: 2 },
        a: { a: 0, k: [159.705, 281.64, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [159.445, -2.81],
                    [157.315, 32.35],
                    [141.485, 95.2],
                    [96.155, 169.15],
                    [17.365, 232.81],
                    [17.345, 232.82],
                    [17.215, 232.89],
                    [17.125, 232.94],
                    [17.045, 232.98],
                    [16.685, 233.18],
                    [-52.975, 265.01],
                    [-108.545, 281.39],
                    [-147.745, 128.15],
                    [-110.345, 116.59],
                    [-73.835, 97.11],
                    [-46.695, 70.57],
                    [-46.685, 70.57],
                    [-31.395, 40.05],
                    [-25.985, 9.13],
                    [-25.765, 0.96],
                    [-29.175, -26.06],
                    [-36.025, -43.69],
                    [-50.295, -63.73],
                    [-72.995, -82.48],
                    [-111.265, -101.54],
                    [-115.015, -102.86],
                    [-158.305, -116.07],
                    [-159.455, -116.37],
                    [-159.455, -281.39],
                    [-158.305, -281.18],
                    [-124.725, -274.7],
                    [-124.715, -274.7],
                    [-54.235, -256.76],
                    [31.915, -221.9],
                    [95.035, -175.66],
                    [146.485, -91.39],
                    [158.685, -26.06],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [159.705, 281.64], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 37.0720720720721,
      op: 80,
      st: 37.0720720720721,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 38,
      ty: 4,
      nm: "Layer 38",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [737.129, 772.838, 0], ix: 2, l: 2 },
        a: { a: 0, k: [170.815, 286.41, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [0, 0],
                    [0.53, -0.07],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [0, 0],
                    [-0.53, 0.08],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [170.555, -7.58],
                    [168.425, 27.58],
                    [152.595, 90.43],
                    [107.265, 164.38],
                    [28.475, 228.04],
                    [28.455, 228.05],
                    [28.325, 228.12],
                    [28.235, 228.17],
                    [28.155, 228.21],
                    [27.795, 228.41],
                    [-41.865, 260.24],
                    [-97.435, 276.62],
                    [-97.435, 276.63],
                    [-145.795, 286.16],
                    [-168.975, 129.47],
                    [-170.565, 129.69],
                    [-136.635, 123.38],
                    [-99.235, 111.82],
                    [-62.725, 92.34],
                    [-35.585, 65.8],
                    [-35.575, 65.8],
                    [-20.285, 35.28],
                    [-14.875, 4.36],
                    [-14.655, -3.81],
                    [-18.065, -30.83],
                    [-24.915, -48.46],
                    [-39.185, -68.5],
                    [-61.885, -87.25],
                    [-100.155, -106.31],
                    [-103.905, -107.63],
                    [-147.195, -120.84],
                    [-148.345, -121.14],
                    [-148.345, -286.16],
                    [-147.195, -285.95],
                    [-113.615, -279.47],
                    [-113.605, -279.47],
                    [-43.125, -261.53],
                    [43.025, -226.67],
                    [106.145, -180.43],
                    [157.595, -96.16],
                    [169.795, -30.83],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [170.815, 286.41], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 38.0740740740741,
      op: 80,
      st: 38.0740740740741,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 39,
      ty: 4,
      nm: "Layer 39",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [715.564, 776.253, 0], ix: 2, l: 2 },
        a: { a: 0, k: [192.38, 289.825, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [192.12, -10.995],
                    [189.99, 24.165],
                    [174.16, 87.015],
                    [128.83, 160.965],
                    [50.04, 224.625],
                    [50.02, 224.635],
                    [49.89, 224.705],
                    [49.8, 224.755],
                    [49.72, 224.795],
                    [49.36, 224.995],
                    [-20.3, 256.825],
                    [-75.87, 273.205],
                    [-75.87, 273.215],
                    [-124.23, 282.745],
                    [-182.99, 289.575],
                    [-192.13, 130.545],
                    [-149, 126.275],
                    [-115.07, 119.965],
                    [-77.67, 108.405],
                    [-41.16, 88.925],
                    [-14.02, 62.385],
                    [-14.01, 62.385],
                    [1.28, 31.865],
                    [6.69, 0.945],
                    [6.91, -7.225],
                    [3.5, -34.245],
                    [-3.35, -51.875],
                    [-17.62, -71.915],
                    [-40.32, -90.665],
                    [-78.59, -109.725],
                    [-82.34, -111.045],
                    [-125.63, -124.255],
                    [-126.78, -124.555],
                    [-126.78, -289.575],
                    [-125.63, -289.365],
                    [-92.05, -282.885],
                    [-92.04, -282.885],
                    [-21.56, -264.945],
                    [64.59, -230.085],
                    [127.71, -183.845],
                    [179.16, -99.575],
                    [191.36, -34.245],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [192.38, 289.825], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 39.0760760760761,
      op: 80,
      st: 39.0760760760761,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 40,
      ty: 4,
      nm: "Layer 40",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [688.999, 777.508, 0], ix: 2, l: 2 },
        a: { a: 0, k: [218.945, 291.08, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [0, 0],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [0, 0],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [218.685, -12.25],
                    [216.555, 22.91],
                    [200.725, 85.76],
                    [155.395, 159.71],
                    [76.605, 223.37],
                    [76.585, 223.38],
                    [76.455, 223.45],
                    [76.365, 223.5],
                    [76.285, 223.54],
                    [75.925, 223.74],
                    [6.265, 255.57],
                    [-49.305, 271.95],
                    [-49.305, 271.96],
                    [-97.665, 281.49],
                    [-156.425, 288.32],
                    [-156.425, 288.33],
                    [-218.695, 290.83],
                    [-213.565, 130.55],
                    [-209.875, 130.56],
                    [-165.565, 129.29],
                    [-122.435, 125.02],
                    [-88.505, 118.71],
                    [-51.105, 107.15],
                    [-14.595, 87.67],
                    [12.545, 61.13],
                    [12.555, 61.13],
                    [27.845, 30.61],
                    [33.255, -0.31],
                    [33.475, -8.48],
                    [30.065, -35.5],
                    [23.215, -53.13],
                    [8.945, -73.17],
                    [-13.755, -91.92],
                    [-52.025, -110.98],
                    [-55.775, -112.3],
                    [-99.065, -125.51],
                    [-100.215, -125.81],
                    [-100.215, -290.83],
                    [-99.065, -290.62],
                    [-65.485, -284.14],
                    [-65.475, -284.14],
                    [5.005, -266.2],
                    [91.155, -231.34],
                    [154.275, -185.1],
                    [205.725, -100.83],
                    [217.925, -35.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [218.945, 291.08], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 40.0780780780781,
      op: 80,
      st: 40.0780780780781,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 41,
      ty: 4,
      nm: "Layer 41",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [655.889, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [252.055, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [0, 0],
                    [1.2, 0.1],
                    [-15.78, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [0, 0],
                    [-1.2, -0.09],
                    [15.66, 1.19],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [251.795, -12.26],
                    [249.665, 22.9],
                    [233.835, 85.75],
                    [188.505, 159.7],
                    [109.715, 223.36],
                    [109.695, 223.37],
                    [109.565, 223.44],
                    [109.475, 223.49],
                    [109.395, 223.53],
                    [109.035, 223.73],
                    [39.375, 255.56],
                    [-16.195, 271.94],
                    [-16.195, 271.95],
                    [-64.555, 281.48],
                    [-123.315, 288.31],
                    [-123.315, 288.32],
                    [-185.585, 290.82],
                    [-190.435, 290.84],
                    [-251.805, 288.62],
                    [-224.025, 128.91],
                    [-227.625, 128.64],
                    [-180.455, 130.54],
                    [-176.765, 130.55],
                    [-132.455, 129.28],
                    [-89.325, 125.01],
                    [-55.395, 118.7],
                    [-17.995, 107.14],
                    [18.515, 87.66],
                    [45.655, 61.12],
                    [45.665, 61.12],
                    [60.955, 30.6],
                    [66.365, -0.32],
                    [66.585, -8.49],
                    [63.175, -35.51],
                    [56.325, -53.14],
                    [42.055, -73.18],
                    [19.355, -91.93],
                    [-18.915, -110.99],
                    [-22.665, -112.31],
                    [-65.955, -125.52],
                    [-67.105, -125.82],
                    [-67.105, -290.84],
                    [-65.955, -290.63],
                    [-32.375, -284.15],
                    [-32.365, -284.15],
                    [38.115, -266.21],
                    [124.265, -231.35],
                    [187.385, -185.11],
                    [238.835, -100.84],
                    [251.035, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [252.055, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 41.0800800800801,
      op: 80,
      st: 41.0800800800801,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 42,
      ty: 4,
      nm: "Layer 42",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [623.039, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [284.905, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [284.645, -12.26],
                    [282.515, 22.9],
                    [266.685, 85.75],
                    [221.355, 159.7],
                    [142.565, 223.36],
                    [142.545, 223.37],
                    [142.415, 223.44],
                    [142.325, 223.49],
                    [142.245, 223.53],
                    [141.885, 223.73],
                    [72.225, 255.56],
                    [16.655, 271.94],
                    [16.655, 271.95],
                    [-31.705, 281.48],
                    [-90.465, 288.31],
                    [-90.465, 288.32],
                    [-152.735, 290.82],
                    [-157.585, 290.84],
                    [-218.955, 288.62],
                    [-284.655, 281.46],
                    [-238.625, 123.81],
                    [-194.775, 128.64],
                    [-194.335, 128.67],
                    [-147.605, 130.54],
                    [-143.915, 130.55],
                    [-99.605, 129.28],
                    [-56.475, 125.01],
                    [-22.545, 118.7],
                    [14.855, 107.14],
                    [51.365, 87.66],
                    [78.505, 61.12],
                    [78.515, 61.12],
                    [93.805, 30.6],
                    [99.215, -0.32],
                    [99.435, -8.49],
                    [96.025, -35.51],
                    [89.175, -53.14],
                    [74.905, -73.18],
                    [52.205, -91.93],
                    [13.935, -110.99],
                    [10.185, -112.31],
                    [-33.105, -125.52],
                    [-34.255, -125.82],
                    [-34.255, -290.84],
                    [-33.105, -290.63],
                    [0.475, -284.15],
                    [0.485, -284.15],
                    [70.965, -266.21],
                    [157.115, -231.35],
                    [220.235, -185.11],
                    [271.685, -100.84],
                    [283.885, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [284.905, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 42.0820820820821,
      op: 80,
      st: 42.0820820820821,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 43,
      ty: 4,
      nm: "Layer 43",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [597.014, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [310.93, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [0, 0],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [0, 0],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [310.67, -12.26],
                    [308.54, 22.9],
                    [292.71, 85.75],
                    [247.38, 159.7],
                    [168.59, 223.36],
                    [168.57, 223.37],
                    [168.44, 223.44],
                    [168.35, 223.49],
                    [168.27, 223.53],
                    [167.91, 223.73],
                    [98.25, 255.56],
                    [42.68, 271.94],
                    [42.68, 271.95],
                    [-5.68, 281.48],
                    [-64.44, 288.31],
                    [-64.44, 288.32],
                    [-126.71, 290.82],
                    [-131.56, 290.84],
                    [-192.93, 288.62],
                    [-258.63, 281.46],
                    [-258.63, 281.47],
                    [-310.68, 272.28],
                    [-244.5, 118.4],
                    [-212.6, 123.81],
                    [-168.75, 128.64],
                    [-168.31, 128.67],
                    [-121.58, 130.54],
                    [-117.89, 130.55],
                    [-73.58, 129.28],
                    [-30.45, 125.01],
                    [3.48, 118.7],
                    [40.88, 107.14],
                    [77.39, 87.66],
                    [104.53, 61.12],
                    [104.54, 61.12],
                    [119.83, 30.6],
                    [125.24, -0.32],
                    [125.46, -8.49],
                    [122.05, -35.51],
                    [115.2, -53.14],
                    [100.93, -73.18],
                    [78.23, -91.93],
                    [39.96, -110.99],
                    [36.21, -112.31],
                    [-7.08, -125.52],
                    [-8.23, -125.82],
                    [-8.23, -290.84],
                    [-7.08, -290.63],
                    [26.5, -284.15],
                    [26.51, -284.15],
                    [96.99, -266.21],
                    [183.14, -231.35],
                    [246.26, -185.11],
                    [297.71, -100.84],
                    [309.91, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [310.93, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 43.0840840840841,
      op: 80,
      st: 43.0840840840841,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 44,
      ty: 4,
      nm: "Layer 44",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [577.709, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [330.235, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [0, 0],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [0, 0],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [329.975, -12.26],
                    [327.845, 22.9],
                    [312.015, 85.75],
                    [266.685, 159.7],
                    [187.895, 223.36],
                    [187.875, 223.37],
                    [187.745, 223.44],
                    [187.655, 223.49],
                    [187.575, 223.53],
                    [187.215, 223.73],
                    [117.555, 255.56],
                    [61.985, 271.94],
                    [61.985, 271.95],
                    [13.625, 281.48],
                    [-45.135, 288.31],
                    [-45.135, 288.32],
                    [-107.405, 290.82],
                    [-112.255, 290.84],
                    [-173.625, 288.62],
                    [-239.325, 281.46],
                    [-239.325, 281.47],
                    [-291.375, 272.28],
                    [-329.985, 263.35],
                    [-265.985, 109.1],
                    [-225.195, 118.4],
                    [-193.295, 123.81],
                    [-149.445, 128.64],
                    [-149.005, 128.67],
                    [-102.275, 130.54],
                    [-98.585, 130.55],
                    [-54.275, 129.28],
                    [-11.145, 125.01],
                    [22.785, 118.7],
                    [60.185, 107.14],
                    [96.695, 87.66],
                    [123.835, 61.12],
                    [123.845, 61.12],
                    [139.135, 30.6],
                    [144.545, -0.32],
                    [144.765, -8.49],
                    [141.355, -35.51],
                    [134.505, -53.14],
                    [120.235, -73.18],
                    [97.535, -91.93],
                    [59.265, -110.99],
                    [55.515, -112.31],
                    [12.225, -125.52],
                    [11.075, -125.82],
                    [11.075, -290.84],
                    [12.225, -290.63],
                    [45.805, -284.15],
                    [45.815, -284.15],
                    [116.295, -266.21],
                    [202.445, -231.35],
                    [265.565, -185.11],
                    [317.015, -100.84],
                    [329.215, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [330.235, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 44.0860860860861,
      op: 80,
      st: 44.0860860860861,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 45,
      ty: 4,
      nm: "Layer 45",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [552.854, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [355.09, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [354.83, -12.26],
                    [352.7, 22.9],
                    [336.87, 85.75],
                    [291.54, 159.7],
                    [212.75, 223.36],
                    [212.73, 223.37],
                    [212.6, 223.44],
                    [212.51, 223.49],
                    [212.43, 223.53],
                    [212.07, 223.73],
                    [142.41, 255.56],
                    [86.84, 271.94],
                    [86.84, 271.95],
                    [38.48, 281.48],
                    [-20.28, 288.31],
                    [-20.28, 288.32],
                    [-82.55, 290.82],
                    [-87.4, 290.84],
                    [-148.77, 288.62],
                    [-214.47, 281.46],
                    [-214.47, 281.47],
                    [-266.52, 272.28],
                    [-305.13, 263.35],
                    [-354.84, 248.93],
                    [-285.52, 95.79],
                    [-241.13, 109.1],
                    [-200.34, 118.4],
                    [-168.44, 123.81],
                    [-124.59, 128.64],
                    [-124.15, 128.67],
                    [-77.42, 130.54],
                    [-73.73, 130.55],
                    [-29.42, 129.28],
                    [13.71, 125.01],
                    [47.64, 118.7],
                    [85.04, 107.14],
                    [121.55, 87.66],
                    [148.69, 61.12],
                    [148.7, 61.12],
                    [163.99, 30.6],
                    [169.4, -0.32],
                    [169.62, -8.49],
                    [166.21, -35.51],
                    [159.36, -53.14],
                    [145.09, -73.18],
                    [122.39, -91.93],
                    [84.12, -110.99],
                    [80.37, -112.31],
                    [37.08, -125.52],
                    [35.93, -125.82],
                    [35.93, -290.84],
                    [37.08, -290.63],
                    [70.66, -284.15],
                    [70.67, -284.15],
                    [141.15, -266.21],
                    [227.3, -231.35],
                    [290.42, -185.11],
                    [341.87, -100.84],
                    [354.07, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [355.09, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 45.0880880880881,
      op: 80,
      st: 45.0880880880881,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 46,
      ty: 4,
      nm: "Layer 46",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [530.169, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [377.775, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [14.49, 5.86],
                    [0, 0],
                    [0.28, 0.12],
                    [-14.53, -4.92],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [-15.73, -5.13],
                    [0, 0],
                    [-0.28, -0.12],
                    [14.17, 5.93],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [377.515, -12.26],
                    [375.385, 22.9],
                    [359.555, 85.75],
                    [314.225, 159.7],
                    [235.435, 223.36],
                    [235.415, 223.37],
                    [235.285, 223.44],
                    [235.195, 223.49],
                    [235.115, 223.53],
                    [234.755, 223.73],
                    [165.095, 255.56],
                    [109.525, 271.94],
                    [109.525, 271.95],
                    [61.165, 281.48],
                    [2.405, 288.31],
                    [2.405, 288.32],
                    [-59.865, 290.82],
                    [-64.715, 290.84],
                    [-126.085, 288.62],
                    [-191.785, 281.46],
                    [-191.785, 281.47],
                    [-243.835, 272.28],
                    [-282.445, 263.35],
                    [-332.155, 248.93],
                    [-332.155, 248.94],
                    [-377.525, 232.42],
                    [-305.045, 79.87],
                    [-305.895, 79.51],
                    [-262.835, 95.79],
                    [-218.445, 109.1],
                    [-177.655, 118.4],
                    [-145.755, 123.81],
                    [-101.905, 128.64],
                    [-101.465, 128.67],
                    [-54.735, 130.54],
                    [-51.045, 130.55],
                    [-6.735, 129.28],
                    [36.395, 125.01],
                    [70.325, 118.7],
                    [107.725, 107.14],
                    [144.235, 87.66],
                    [171.375, 61.12],
                    [171.385, 61.12],
                    [186.675, 30.6],
                    [192.085, -0.32],
                    [192.305, -8.49],
                    [188.895, -35.51],
                    [182.045, -53.14],
                    [167.775, -73.18],
                    [145.075, -91.93],
                    [106.805, -110.99],
                    [103.055, -112.31],
                    [59.765, -125.52],
                    [58.615, -125.82],
                    [58.615, -290.84],
                    [59.765, -290.63],
                    [93.345, -284.15],
                    [93.355, -284.15],
                    [163.835, -266.21],
                    [249.985, -231.35],
                    [313.105, -185.11],
                    [364.555, -100.84],
                    [376.755, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [377.775, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 46.0900900900901,
      op: 80,
      st: 46.0900900900901,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 47,
      ty: 4,
      nm: "Layer 47",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [509.819, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [398.125, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [14.49, 5.86],
                    [0, 0],
                    [12.84, 6.37],
                    [0, 0],
                    [0.61, 0.31],
                    [-12.96, -5.42],
                    [-14.53, -4.92],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [-15.73, -5.13],
                    [0, 0],
                    [-14.26, -5.76],
                    [0, 0],
                    [-0.61, -0.3],
                    [12.6, 6.27],
                    [14.17, 5.93],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [397.865, -12.26],
                    [395.735, 22.9],
                    [379.905, 85.75],
                    [334.575, 159.7],
                    [255.785, 223.36],
                    [255.765, 223.37],
                    [255.635, 223.44],
                    [255.545, 223.49],
                    [255.465, 223.53],
                    [255.105, 223.73],
                    [185.445, 255.56],
                    [129.875, 271.94],
                    [129.875, 271.95],
                    [81.515, 281.48],
                    [22.755, 288.31],
                    [22.755, 288.32],
                    [-39.515, 290.82],
                    [-44.365, 290.84],
                    [-105.735, 288.62],
                    [-171.435, 281.46],
                    [-171.435, 281.47],
                    [-223.485, 272.28],
                    [-262.095, 263.35],
                    [-311.805, 248.93],
                    [-311.805, 248.94],
                    [-357.175, 232.42],
                    [-357.175, 232.43],
                    [-397.875, 214.21],
                    [-322.055, 62.9],
                    [-323.895, 61.98],
                    [-285.545, 79.51],
                    [-242.485, 95.79],
                    [-198.095, 109.1],
                    [-157.305, 118.4],
                    [-125.405, 123.81],
                    [-81.555, 128.64],
                    [-81.115, 128.67],
                    [-34.385, 130.54],
                    [-30.695, 130.55],
                    [13.615, 129.28],
                    [56.745, 125.01],
                    [90.675, 118.7],
                    [128.075, 107.14],
                    [164.585, 87.66],
                    [191.725, 61.12],
                    [191.735, 61.12],
                    [207.025, 30.6],
                    [212.435, -0.32],
                    [212.655, -8.49],
                    [209.245, -35.51],
                    [202.395, -53.14],
                    [188.125, -73.18],
                    [165.425, -91.93],
                    [127.155, -110.99],
                    [123.405, -112.31],
                    [80.115, -125.52],
                    [78.965, -125.82],
                    [78.965, -290.84],
                    [80.115, -290.63],
                    [113.695, -284.15],
                    [113.705, -284.15],
                    [184.185, -266.21],
                    [270.335, -231.35],
                    [333.455, -185.11],
                    [384.905, -100.84],
                    [397.105, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [398.125, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 47.0920920920921,
      op: 80,
      st: 47.0920920920921,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 48,
      ty: 4,
      nm: "Layer 48",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [490.694, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [417.25, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [14.49, 5.86],
                    [0, 0],
                    [12.84, 6.37],
                    [11.8, 7.35],
                    [0, 0],
                    [-11.42, -5.65],
                    [-12.96, -5.42],
                    [-14.53, -4.92],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [-15.73, -5.13],
                    [0, 0],
                    [-14.26, -5.76],
                    [-13.65, -6.78],
                    [0, 0],
                    [11.1, 6.34],
                    [12.6, 6.27],
                    [14.17, 5.93],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [416.99, -12.26],
                    [414.86, 22.9],
                    [399.03, 85.75],
                    [353.7, 159.7],
                    [274.91, 223.36],
                    [274.89, 223.37],
                    [274.76, 223.44],
                    [274.67, 223.49],
                    [274.59, 223.53],
                    [274.23, 223.73],
                    [204.57, 255.56],
                    [149, 271.94],
                    [149, 271.95],
                    [100.64, 281.48],
                    [41.88, 288.31],
                    [41.88, 288.32],
                    [-20.39, 290.82],
                    [-25.24, 290.84],
                    [-86.61, 288.62],
                    [-152.31, 281.46],
                    [-152.31, 281.47],
                    [-204.36, 272.28],
                    [-242.97, 263.35],
                    [-292.68, 248.93],
                    [-292.68, 248.94],
                    [-338.05, 232.42],
                    [-338.05, 232.43],
                    [-378.75, 214.21],
                    [-417, 192.98],
                    [-338.56, 43.98],
                    [-304.77, 61.98],
                    [-266.42, 79.51],
                    [-223.36, 95.79],
                    [-178.97, 109.1],
                    [-138.18, 118.4],
                    [-106.28, 123.81],
                    [-62.43, 128.64],
                    [-61.99, 128.67],
                    [-15.26, 130.54],
                    [-11.57, 130.55],
                    [32.74, 129.28],
                    [75.87, 125.01],
                    [109.8, 118.7],
                    [147.2, 107.14],
                    [183.71, 87.66],
                    [210.85, 61.12],
                    [210.86, 61.12],
                    [226.15, 30.6],
                    [231.56, -0.32],
                    [231.78, -8.49],
                    [228.37, -35.51],
                    [221.52, -53.14],
                    [207.25, -73.18],
                    [184.55, -91.93],
                    [146.28, -110.99],
                    [142.53, -112.31],
                    [99.24, -125.52],
                    [98.09, -125.82],
                    [98.09, -290.84],
                    [99.24, -290.63],
                    [132.82, -284.15],
                    [132.83, -284.15],
                    [203.31, -266.21],
                    [289.46, -231.35],
                    [352.58, -185.11],
                    [404.03, -100.84],
                    [416.23, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [417.25, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 48.0940940940941,
      op: 80,
      st: 48.0940940940941,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 49,
      ty: 4,
      nm: "Layer 49",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [475.409, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.535, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [14.49, 5.86],
                    [0, 0],
                    [12.84, 6.37],
                    [11.8, 7.35],
                    [9.25, 7.34],
                    [0, 0],
                    [-10.72, -6.11],
                    [-11.42, -5.65],
                    [-12.96, -5.42],
                    [-14.53, -4.92],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [-15.73, -5.13],
                    [0, 0],
                    [-14.26, -5.76],
                    [-13.65, -6.78],
                    [-11.09, -6.91],
                    [0, 0],
                    [10.4, 6.74],
                    [11.1, 6.34],
                    [12.6, 6.27],
                    [14.17, 5.93],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [432.275, -12.26],
                    [430.145, 22.9],
                    [414.315, 85.75],
                    [368.985, 159.7],
                    [290.195, 223.36],
                    [290.175, 223.37],
                    [290.045, 223.44],
                    [289.955, 223.49],
                    [289.875, 223.53],
                    [289.515, 223.73],
                    [219.855, 255.56],
                    [164.285, 271.94],
                    [164.285, 271.95],
                    [115.925, 281.48],
                    [57.165, 288.31],
                    [57.165, 288.32],
                    [-5.105, 290.82],
                    [-9.955, 290.84],
                    [-71.325, 288.62],
                    [-137.025, 281.46],
                    [-137.025, 281.47],
                    [-189.075, 272.28],
                    [-227.685, 263.35],
                    [-277.395, 248.93],
                    [-277.395, 248.94],
                    [-322.765, 232.42],
                    [-322.765, 232.43],
                    [-363.465, 214.21],
                    [-401.715, 192.98],
                    [-432.285, 171.58],
                    [-354.955, 24.69],
                    [-323.275, 43.98],
                    [-289.485, 61.98],
                    [-251.135, 79.51],
                    [-208.075, 95.79],
                    [-163.685, 109.1],
                    [-122.895, 118.4],
                    [-90.995, 123.81],
                    [-47.145, 128.64],
                    [-46.705, 128.67],
                    [0.025, 130.54],
                    [3.715, 130.55],
                    [48.025, 129.28],
                    [91.155, 125.01],
                    [125.085, 118.7],
                    [162.485, 107.14],
                    [198.995, 87.66],
                    [226.135, 61.12],
                    [226.145, 61.12],
                    [241.435, 30.6],
                    [246.845, -0.32],
                    [247.065, -8.49],
                    [243.655, -35.51],
                    [236.805, -53.14],
                    [222.535, -73.18],
                    [199.835, -91.93],
                    [161.565, -110.99],
                    [157.815, -112.31],
                    [114.525, -125.52],
                    [113.375, -125.82],
                    [113.375, -290.84],
                    [114.525, -290.63],
                    [148.105, -284.15],
                    [148.115, -284.15],
                    [218.595, -266.21],
                    [304.745, -231.35],
                    [367.865, -185.11],
                    [419.315, -100.84],
                    [431.515, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.535, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 49.0960960960961,
      op: 80,
      st: 49.0960960960961,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 50,
      ty: 4,
      nm: "Layer 50",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [475.109, 777.518, 0], ix: 2, l: 2 },
        a: { a: 0, k: [432.835, 291.09, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.01, -7.99],
                    [1.42, -11.43],
                    [7.79, -19.77],
                    [19.48, -22.23],
                    [34.06, -18.76],
                    [0.01, -0.01],
                    [0.04, -0.02],
                    [0.03, -0.02],
                    [0.03, -0.02],
                    [0.12, -0.07],
                    [25.27, -9],
                    [19.47, -4.64],
                    [0, 0],
                    [16.69, -2.64],
                    [20.28, -1.56],
                    [0, 0],
                    [21.41, -0.1],
                    [1.62, 0],
                    [20.33, 1.46],
                    [21.57, 3.16],
                    [0, 0],
                    [17.02, 3.53],
                    [12.62, 3.23],
                    [16.02, 5.23],
                    [0, 0],
                    [14.49, 5.86],
                    [0, 0],
                    [12.84, 6.37],
                    [11.8, 7.35],
                    [9.25, 7.34],
                    [0.2, 0.16],
                    [0, 0],
                    [-0.18, -0.12],
                    [-10.72, -6.11],
                    [-11.42, -5.65],
                    [-12.96, -5.42],
                    [-14.53, -4.92],
                    [-14.95, -3.91],
                    [-13.7, -2.67],
                    [-10.68, -1.54],
                    [-14.69, -1.12],
                    [-0.15, -0.01],
                    [-15.63, -0.07],
                    [-1.23, 0],
                    [-13.96, 0.84],
                    [-13.43, 1.92],
                    [-10.56, 2.49],
                    [-11.25, 4.47],
                    [-10.37, 7.4],
                    [-7.12, 9.81],
                    [0, 0],
                    [-3.44, 10.99],
                    [-0.59, 10.91],
                    [0, 2.76],
                    [2.24, 8.28],
                    [2.87, 5.5],
                    [5.68, 6.1],
                    [8.62, 5.62],
                    [14.23, 5.52],
                    [1.86, 0.65],
                    [19.02, 5.04],
                    [0.39, 0.1],
                    [0, 0],
                    [-0.38, -0.07],
                    [-8.08, -1.83],
                    [0, 0],
                    [-22.84, -6.93],
                    [-26.36, -14.28],
                    [-18.19, -18.3],
                    [-11.07, -33.89],
                    [-1.61, -24.05],
                  ],
                  o: [
                    [0, 12.01],
                    [-2.72, 22.09],
                    [-10.63, 26.99],
                    [-22.92, 26.16],
                    [-0.01, 0],
                    [-0.04, 0.02],
                    [-0.03, 0.01],
                    [-0.03, 0.01],
                    [-0.12, 0.07],
                    [-21.12, 12.18],
                    [-17.57, 6.27],
                    [0, 0],
                    [-15.54, 3.71],
                    [-18.88, 2.98],
                    [0, 0],
                    [-20.09, 1.55],
                    [-1.61, 0.02],
                    [-20.53, 0],
                    [-22.17, -1.59],
                    [0, 0],
                    [-17.64, -2.58],
                    [-13.1, -2.72],
                    [-17.08, -4.37],
                    [0, 0],
                    [-15.73, -5.13],
                    [0, 0],
                    [-14.26, -5.76],
                    [-13.65, -6.78],
                    [-11.09, -6.91],
                    [-0.2, -0.15],
                    [0, 0],
                    [0.18, 0.12],
                    [10.4, 6.74],
                    [11.1, 6.34],
                    [12.6, 6.27],
                    [14.17, 5.93],
                    [14.63, 4.96],
                    [13.49, 3.53],
                    [10.58, 2.07],
                    [14.53, 2.1],
                    [0.15, 0.01],
                    [15.51, 1.17],
                    [1.23, 0.01],
                    [15.59, 0],
                    [15.33, -0.92],
                    [12.06, -1.71],
                    [13.7, -3.22],
                    [14, -5.57],
                    [11, -7.86],
                    [0, 0],
                    [6.78, -9.33],
                    [3.04, -9.7],
                    [0.15, -2.69],
                    [0, -9.76],
                    [-1.69, -6.26],
                    [-3.79, -7.29],
                    [-6.46, -6.92],
                    [-11.17, -7.28],
                    [-0.6, -0.22],
                    [-7.48, -2.58],
                    [-0.38, -0.1],
                    [0, 0],
                    [0.38, 0.07],
                    [12.61, 2.25],
                    [0, 0],
                    [23.97, 5.17],
                    [30.65, 9.31],
                    [23.58, 12.77],
                    [22.63, 22.77],
                    [6.41, 19.59],
                    [0.52, 7.52],
                  ],
                  v: [
                    [432.575, -12.26],
                    [430.445, 22.9],
                    [414.615, 85.75],
                    [369.285, 159.7],
                    [290.495, 223.36],
                    [290.475, 223.37],
                    [290.345, 223.44],
                    [290.255, 223.49],
                    [290.175, 223.53],
                    [289.815, 223.73],
                    [220.155, 255.56],
                    [164.585, 271.94],
                    [164.585, 271.95],
                    [116.225, 281.48],
                    [57.465, 288.31],
                    [57.465, 288.32],
                    [-4.805, 290.82],
                    [-9.655, 290.84],
                    [-71.025, 288.62],
                    [-136.725, 281.46],
                    [-136.725, 281.47],
                    [-188.775, 272.28],
                    [-227.385, 263.35],
                    [-277.095, 248.93],
                    [-277.095, 248.94],
                    [-322.465, 232.42],
                    [-322.465, 232.43],
                    [-363.165, 214.21],
                    [-401.415, 192.98],
                    [-431.985, 171.58],
                    [-432.585, 171.11],
                    [-355.195, 24.34],
                    [-354.655, 24.69],
                    [-322.975, 43.98],
                    [-289.185, 61.98],
                    [-250.835, 79.51],
                    [-207.775, 95.79],
                    [-163.385, 109.1],
                    [-122.595, 118.4],
                    [-90.695, 123.81],
                    [-46.845, 128.64],
                    [-46.405, 128.67],
                    [0.325, 130.54],
                    [4.015, 130.55],
                    [48.325, 129.28],
                    [91.455, 125.01],
                    [125.385, 118.7],
                    [162.785, 107.14],
                    [199.295, 87.66],
                    [226.435, 61.12],
                    [226.445, 61.12],
                    [241.735, 30.6],
                    [247.145, -0.32],
                    [247.365, -8.49],
                    [243.955, -35.51],
                    [237.105, -53.14],
                    [222.835, -73.18],
                    [200.135, -91.93],
                    [161.865, -110.99],
                    [158.115, -112.31],
                    [114.825, -125.52],
                    [113.675, -125.82],
                    [113.675, -290.84],
                    [114.825, -290.63],
                    [148.405, -284.15],
                    [148.415, -284.15],
                    [218.895, -266.21],
                    [305.045, -231.35],
                    [368.165, -185.11],
                    [419.615, -100.84],
                    [431.815, -35.51],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.20000001496, 0.20000001496, 0.20000001496, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [432.835, 291.09], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 50.0980980980981,
      op: 80,
      st: 50.0980980980981,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 51,
      ty: 4,
      nm: "Layer 51",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 303.328, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 1.62, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 1.37],
                    [-83.175, 1.37],
                    [-83.175, -1.37],
                    [83.175, -1.37],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 1.62], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 52.1001001001001,
      op: 80,
      st: 52.1001001001001,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 52,
      ty: 4,
      nm: "Layer 52",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 321.463, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 19.755, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 19.505],
                    [-83.175, 19.505],
                    [-83.175, -19.505],
                    [83.175, -19.505],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 19.755], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 53.1021021021021,
      op: 80,
      st: 53.1021021021021,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 53,
      ty: 4,
      nm: "Layer 53",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 339.603, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 37.895, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 37.645],
                    [-83.175, 37.645],
                    [-83.175, -37.645],
                    [83.175, -37.645],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 37.895], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 54.1041041041041,
      op: 80,
      st: 54.1041041041041,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 54,
      ty: 4,
      nm: "Layer 54",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 357.738, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 56.03, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 55.78],
                    [-83.175, 55.78],
                    [-83.175, -55.78],
                    [83.175, -55.78],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 56.03], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 55.1061061061061,
      op: 80,
      st: 55.1061061061061,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 55,
      ty: 4,
      nm: "Layer 55",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 375.873, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 74.165, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 73.915],
                    [-83.175, 73.915],
                    [-83.175, -73.915],
                    [83.175, -73.915],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 74.165], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 56.1081081081081,
      op: 80,
      st: 56.1081081081081,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 56,
      ty: 4,
      nm: "Layer 56",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 394.008, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 92.3, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 92.05],
                    [-83.175, 92.05],
                    [-83.175, -92.05],
                    [83.175, -92.05],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 92.3], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 57.1101101101101,
      op: 80,
      st: 57.1101101101101,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 57,
      ty: 4,
      nm: "Layer 57",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 412.148, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 110.44, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 110.19],
                    [-83.175, 110.19],
                    [-83.175, -110.19],
                    [83.175, -110.19],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 110.44], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 58.1121121121121,
      op: 80,
      st: 58.1121121121121,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 58,
      ty: 4,
      nm: "Layer 58",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 430.283, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 128.575, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 128.325],
                    [-83.175, 128.325],
                    [-83.175, -128.325],
                    [83.175, -128.325],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 128.575], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 59.1141141141141,
      op: 80,
      st: 59.1141141141141,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 59,
      ty: 4,
      nm: "Layer 59",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 448.418, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 146.71, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 146.46],
                    [-83.175, 146.46],
                    [-83.175, -146.46],
                    [83.175, -146.46],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 146.71], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 60.1161161161161,
      op: 80,
      st: 60.1161161161161,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 60,
      ty: 4,
      nm: "Layer 60",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 466.558, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 164.85, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 164.6],
                    [-83.175, 164.6],
                    [-83.175, -164.6],
                    [83.175, -164.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 164.85], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 61.1181181181181,
      op: 80,
      st: 61.1181181181181,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 61,
      ty: 4,
      nm: "Layer 61",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 484.693, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 182.985, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 182.735],
                    [-83.175, 182.735],
                    [-83.175, -182.735],
                    [83.175, -182.735],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 182.985], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 62.1201201201201,
      op: 80,
      st: 62.1201201201201,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 62,
      ty: 4,
      nm: "Layer 62",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 502.828, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 201.12, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 200.87],
                    [-83.175, 200.87],
                    [-83.175, -200.87],
                    [83.175, -200.87],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 201.12], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 63.1221221221221,
      op: 80,
      st: 63.1221221221221,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 63,
      ty: 4,
      nm: "Layer 63",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 520.968, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 219.26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 219.01],
                    [-83.175, 219.01],
                    [-83.175, -219.01],
                    [83.175, -219.01],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 219.26], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 64.1241241241241,
      op: 80,
      st: 64.1241241241241,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 64,
      ty: 4,
      nm: "Layer 64",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [474.999, 538.908, 0], ix: 2, l: 2 },
        a: { a: 0, k: [83.425, 237.2, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [83.175, 236.95],
                    [-83.175, 236.95],
                    [-83.175, -236.95],
                    [83.175, -236.95],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.376470618154, 0.282352941176, 0.470588265213, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [83.425, 237.2], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 65.1261261261261,
      op: 80,
      st: 65.1261261261261,
      bm: 0,
    },
  ],
  markers: [],
};
