import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/suretiloader.svg";
import Loader from "./jsonLoader.jsx";

const SuretiLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} width={100} />
    </div>
  );
};

export default SuretiLoader;
