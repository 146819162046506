import React, { Suspense, lazy } from "react";
import Notifications from "react-notify-toast";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
const App = lazy(() => import("./app/App"));

// import history from "./app/utils/history";
import { BrowserRouter } from "react-router-dom";
const AuthContext = lazy(() => import("./app/context/userContext"));

import SuretiLoader from "./app/pages/components/suretiLoader";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense lazy={<SuretiLoader />}>
    <BrowserRouter>
      <AuthContext>
        <Notifications options={{ zIndex: 200, top: "50px" }} />
        <App />
      </AuthContext>
    </BrowserRouter>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
